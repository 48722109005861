<template>
  <v-container fluid>
    <v-treeview
      v-model="chartTree"
      color="accent"
      :hoverable="trueValue"
      item-text="AcctName"
      item-children="children"
      :items="items"
      item-key="id"
      :search="search"
      open-all
    >
      <!-- <template v-slot:label="{ item }">
        <div>
          <span style="font-weight: bold">{{ item.AcctCode }}</span>
          -- {{ item.AcctName }}
        </div>
      </template> -->
    </v-treeview>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      loading: false,
      trueValue: true,
      falseValue: false,
      chartData: [],
      accountData: [],
      chartTree: [],
      levels: [],
      record: {},
      headers: [{ text: "Name", value: "AcctName" }],
      items: [
        {
          id: 1,
          AcctName: "Applications :",
          children: [
            { id: 2, AcctName: "Calendar : app" },
            { id: 3, AcctName: "Chrome : app" },
            { id: 4, AcctName: "Webstorm : app" },
          ],
        },
        {
          id: 5,
          AcctName: "Documents :",
          children: [
            {
              id: 6,
              AcctName: "vuetify :",
              children: [
                {
                  id: 7,
                  AcctName: "src :",
                  children: [
                    { id: 8, AcctName: "index : ts" },
                    { id: 9, AcctName: "bootstrap : ts" },
                  ],
                },
              ],
            },
            {
              id: 10,
              AcctName: "material2 :",
              children: [
                {
                  id: 11,
                  name: "src :",
                  children: [
                    { id: 12, AcctName: "v-btn : ts" },
                    { id: 13, AcctName: "v-card : ts" },
                    { id: 14, AcctName: "v-window : ts" },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 15,
          AcctName: "Downloads :",
          children: [
            { id: 16, AcctName: "October : pdf" },
            { id: 17, AcctName: "November : pdf" },
            { id: 18, AcctName: "Tutorial : html" },
          ],
        },
        {
          id: 19,
          AcctName: "Videos :",
          children: [
            {
              id: 20,
              AcctName: "Tutorials :",
              children: [
                { id: 21, AcctName: "Basic layouts : mp4" },
                { id: 22, AcctName: "Advanced techniques : mp4" },
                { id: 23, AcctName: "All about app : dir" },
              ],
            },
            { id: 24, AcctName: "Intro : mov" },
            { id: 25, AcctName: "Conference introduction : avi" },
          ],
        },
      ],
    };
  },
  methods: {
    getAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/chartofaccounts/86`)
        .then((res) => {
          self.accountData = res.data;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getAccounts();
  },
};
</script>

<style lang="scss" scoped>
</style>